<template>
  <div>
    <b-row>
      <b-col>
        <b-card>

          <b-row>
            <h4 class="ml-1 mb-2">
              Detail BBM
            </h4>
            <b-col cols="12">
              <label>Kendaraan:</label>
              <p>{{ model.vehicle.number }}</p>
              <label>Lokasi:</label>
              <p>{{ model.location }}</p>
              <label>KM:</label>
              <p>{{ model.odometer }}</p>
              <label>Jumlah (liter):</label>
              <p>{{ model.liter }}</p>
              <label>Harga:</label>
              <p>{{ model.price }}</p>
              <label v-if="model.author != null">Petugas Pelaporan:</label>
              <p v-if="model.author != null">
                {{ model.author.name }}
              </p>
              <label>Tanggal:</label>
              <p>{{ tanggal(model.created_at) }}</p>
              <br>
              <span
                v-if="model.status == 'open'"
                class="badge rounded-pill bg-info"
              >Baru</span>
              <span
                v-if="model.status == 'solved'"
                class="badge rounded-pill bg-success"
              >Selesai</span>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <hr>
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <b-button
                  style="margin-right: 5px;"
                  variant="primary"
                  type="button"
                  @click.prevent="editData(model)"
                >
                  Edit
                </b-button>
                <b-button
                  style="margin-right: 5px;"
                  variant="danger"
                  type="button"
                  @click.prevent="confirmDelete(model)"
                >
                  Hapus
                </b-button>
                <!-- <b-button
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="cancel"
                >
                  Kembali
                </b-button> -->
              </div>
              
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-2"><feather-icon
            icon="ListIcon"
          /> Bukti</h4>
                  
          <label>Foto Odometer:</label>
          <br>
          <a
            :href="model.odometer_evidence"
            target="_blank"
          ><img
            :src="model.odometer_evidence"
            width="200"
            alt=""
          ></a>
          <br>
          <br>
          <label>Foto Nota:</label>
          <br>
          <a
            :href="model.receipt_evidence"
            target="_blank"
          ><img
            :src="model.receipt_evidence"
            width="200"
            alt=""
          ></a>
          <br>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol, BButton,BSpinner,BCard,BTabs, BTab} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import FormLabel from '@/views/base/form/FormLabel.vue'
import moment from "moment"


export default {
  components: {

    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    FormLabel,
    BTabs, BTab,
  },
  data() {
    return {
      loading :false,
      model:{},
      baseroute:'refueling',
      posturl:'/refueling',
      tombol_hide:true,
      ajukan:'',
      renderComp:true,
      title_modal:null,
      data_ajukan:[],
      data_item:{},
      data_time_line:{}
    }
  },
  beforeMount(){
    this.$http.get('/staff').then(ref=>{
        this.data_ajukan = ref.data.data
    })
  },
  mounted(){
    this.getData()
  },
  methods: {
     getData(){
        this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
          this.model = res.data
          this.data_time_line = res.data.tracks
        })
     },
    editData(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.id} })
    },
    tanggal(date){
        return moment(date).local('id').format('DD-MM-YYYY')
      },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.contract_subject != null ? 'Anda Ingin Menghapus Data '+data.contract_subject +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.posturl+'/'+data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })

            })
            .catch(err=>{
              this.errorSubmit(err)
            })
          }
        })
    },
   
    cancel(){
        this.$router.push({ name:this.$route.meta.pageActive })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide()
      this.$refs['my-modal-terima'].hide()
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  },
}
</script>
